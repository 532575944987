<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-text-field
        v-model="form.name"
        :rules="$rules.required"
        label="Nome"
        required
        dense
        outlined
      ></v-text-field>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.confirmation_kind"
            label="Código da forma de confirmação"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="Tipo"
            :items="types"
            v-model="form.output_type"
            item-value="value"
            item-text="name"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <!-- chatbot_maker -->
      <v-row v-show="form.output_type === 'chatbot_maker'">
        <v-col>
          <v-text-field
            v-model="connection_infos.api_url"
            :rules="validate('connection_infos.api_url')"
            label="Informe a URL"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.token"
            label="Informe o token"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- email -->
      <v-row v-show="form.output_type === 'email'">
        <v-col>
          <v-textarea
            v-model="connection_infos.smtp_settings"
            label="Configurações SMTP"
            required
            dense
            outlined
          ></v-textarea>
        </v-col>
        <v-col>
          <div>
            <b>Opções</b>
            <div>email_remetente</div>
            <div>nome_remetente</div>
            <div>endereco_smtp</div>
            <div>porta_smtp</div>
            <div>login</div>
            <div>senha</div>
          </div>
        </v-col>
      </v-row>

      <!-- email send grid -->
      <v-row v-show="form.output_type === 'email_sendgrid'">
        <v-col>
          <v-text-field
            v-model="connection_infos.sendgrid_sender_name"
            label="Nome do remetente"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.sendgrid_sender_email"
            label="E-mail do remetente"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.sendgrid_api_key"
            label="API KEY"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- SMS infobip -->
      <v-row v-show="form.output_type === 'sms_infobip'">
        <v-col>
          <v-text-field
            v-model="connection_infos.username"
            label="Nome de usuário"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.password"
            label="Senha"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- SMS wavy -->
      <v-row v-show="form.output_type === 'sms_wavy'">
        <v-col>
          <v-text-field
            v-model="connection_infos.username"
            label="Nome de usuário"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.token"
            label="Token"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- SMS wavy -->
      <v-row v-show="form.output_type === 'sms_wavy'">
        <v-col>
          <v-text-field
            v-model="connection_infos.api_url"
            label="URL da API"
            placeholder="URL da API SMS Comunicante"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.username"
            label="Nome de usuário"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.password"
            label="Senha"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- URA -->
      <v-row v-show="form.output_type === 'ura_tactium'">
        <v-col>
          <v-text-field
            v-model="connection_infos.campaign_id"
            label="Código da campanha"
            placeholder="Informe o código da campanha"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.conection"
            label="Conexão com DB"
            placeholder="Informe a conexão com o DB"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="Versão"
            :items="[1, 2]"
            v-model="connection_infos.ura_version"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <!-- Whatsapp Izy -->
      <v-row v-show="form.output_type === 'whatsapp_izy'">
        <v-col>
          <v-text-field
            v-model="connection_infos.whatsapp_izy_api"
            label="URL para envio de mensagem"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.whatsapp_izy_authentication_url"
            :rules="
              validate('connection_infos.whatsapp_izy_authentication_url')
            "
            label="URL para autenticação"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="connection_infos.whatsapp_izy_process"
            label="Processo"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.v-form {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'OutboxForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    loading: false,
    types: [],
    errors: [],
    connection_infos: {
      api_url: null,
      username: null,
      password: null,
      token: null,
      id_campanha: null,
      conexao: null,
      sendgrid_sender_name: null,
      sendgrid_sender_email: null,
      sendgrid_api_key: null,
      smtp_settings: null,
      ura_version: null,
      whatsapp_izy_api: null,
      whatsapp_izy_authentication_url: null,
      whatsapp_izy_process: null,
    },
    form: {
      name: null,
      output_type: null,
      confirmation_kind: null,
    },
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    validate(field) {
      const errorMsg =
        this.errors[field] == null ? false : this.errors[field].join(',')
      if (errorMsg) {
        return [value => !!value || errorMsg]
      }

      return []
    },

    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'TaskSchedulers' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show(
                  'Erro ao remover Agendamento de Tarefas',
                  'error'
                )
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const formPayload = {
        id: data.id,
        name: data.name,
        output_type: data.output_type,
        confirmation_kind: data.confirmation_kind,
      }
      this.form = { ...this.form, ...formPayload }

      const connectionInfosPayload = JSON.parse(data.connection_infos)
      this.connection_infos = { ...this.form, ...connectionInfosPayload }
    },

    async fetch(id = null) {
      try {
        this.loading = true

        const typesResult = await Api.outboxes.types()
        this.types = typesResult.data.types

        if (id === null) {
          return
        }

        const outboxResult = await Api.outboxes.fetch(id)
        this._parseForm(outboxResult.data.outbox)
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar Caixa de Saída')
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      Api.outboxes
        .save(this.$route.params.id, {
          outbox: this.form,
          connection_infos: this.connection_infos,
        })
        .then(response => {
          this._parseForm(response.data.outbox)
          this.$root.$children[0].toast('Caixa de saída salva com sucesso')

          this.$router.push({
            name: 'OutboxShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar Caixa de Saída')
            if (error.response.status != 500) {
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
